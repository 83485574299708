<template>
  <div>
    <v-form data-vv-scope="form-deal" data-testid="form-deal">
      <v-card class="mb-2">
        <v-card-title>
          <span class="subtitle-1" v-html="getTitle()"></span>
        </v-card-title>
        <v-card-text>
          <v-row class="pt-3">
            <v-col
              cols="12"
              md="12"
              v-if="errors.has('form-bundle-registered.general_error_field')"
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-alert type="error" text>
                    {{ errors.first("form-deal.general_error_field") }}
                  </v-alert>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" sm="6" v-if="editedIndex === -1">
              <v-text-field
                v-model="activeDeal.hubspot_key"
                :error-messages="errors.collect('form-deal.hubspot_key')"
                label="HubSpot Key"
                placeholder=""
                data-vv-name="hubspot_key"
                data-vv-validate-on="change"
                data-vv-as="HubSpot Key"
                outlined
                v-validate="'required'"
                dense
                data-testid="hubspot-key"
              />
            </v-col>

            <v-col cols="12" sm="6" v-if="editedIndex !== -1">
              <v-text-field
                v-model="activeDeal.name"
                :error-messages="errors.collect('form-deal.name')"
                label="Name"
                placeholder=""
                data-vv-name="name"
                data-vv-validate-on="change"
                data-vv-as="Name"
                outlined
                v-validate="'required'"
                dense
                data-testid="name"
              />
            </v-col>

            <v-col cols="12" sm="6" v-if="editedIndex !== -1">
              <v-text-field
                v-model.trim="activeDeal.hs_contact_email"
                :error-messages="errors.collect('form-deal.hs_contact_email')"
                label="HubSpot Email"
                placeholder=""
                data-vv-name="hs_contact_email"
                data-vv-validate-on="change"
                data-vv-as="HubSpot Email"
                outlined
                v-validate="'required'"
                dense
                data-testid="hs-contact-email"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="error"
            text
            :to="'/store/deals'"
            data-testid="btn-close"
          >
            Close
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="save()"
            data-testid="btn-save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import GeneralMixin from "@/mixins/general.js"
import StoreMixin from "@/mixins/store/common.js"

export default {
  props: {
    editedIndex: {
      type: [Number, String],
      default: -1,
    },
  },

  data: () => ({}),

  mixins: [GeneralMixin, StoreMixin],

  created: function () {
    let self = this
    self.$store.dispatch("saveLoaderAction", true)
    self.$store.dispatch("resetDealItem").then(() => {
      self.getItemData().then(() => {
        self.$store.dispatch("saveLoaderAction", false)
      })
    })
  },

  beforeDestroy() {
    this.$store.dispatch("resetDealItem")
  },

  methods: {
    getTitle() {
      return this.editedIndex === -1
        ? "Add new deal"
        : `<span class="blue-grey--text text--lighten-4">Edit deal</span> ${this.dealName}`
    },
    getItemData() {
      return this.editedIndex !== -1
        ? this.$store.dispatch("getDealItem", {
            slug: this.editedIndex,
          })
        : Promise.resolve()
    },
    createDeal(formData) {
      let self = this
      self.$store
        .dispatch("createDeal", formData)
        .then(({ data }) => {
          let params = {
            text: `Deal <strong>${this.activeDeal.hubspot_key}</strong> was added successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-deal")
        })
    },
    editDeal(formData) {
      let self = this
      self.$store
        .dispatch("editDeal", formData)
        .then(({ data }) => {
          let params = {
            text: `Deal <strong>${this.dealName}</strong> was edited successfully.`,
            show: true,
          }
          self.close().then(() => {
            self.$root.$emit("snackbar", params)
          })
        })
        .catch(({ response }) => {
          self.handleValidationErrors(response, "form-deal")
        })
    },
    save() {
      let self = this
      self.$validator.errors.clear("form-deal")
      self.$validator.validateAll("form-deal").then((result) => {
        if (result) {
          let formData = {
            data: {},
          }
          if (this.editedIndex !== -1) {
            formData["slug"] = self.editedIndex
            formData.data.hs_contact_email = self.activeDeal.hs_contact_email
            formData.data.name = self.activeDeal.name
            self.editDeal(formData)
          } else {
            formData.data.hubspot_key = self.activeDeal.hubspot_key
            self.createDeal(formData)
          }
        }
      })
    },
    close() {
      let self = this
      return new Promise((resolve, reject) => {
        this.$router
          .push({ path: "/store/deals" })
          .then(() => {
            resolve()
          })
          .catch((err) => {})
      })
    },
  },

  computed: {
    ...mapGetters({
      showTableLoader: "getShowTableLoader",
      activeDeal: "activeDeal",
      Pallette: "get_Pallette",
      isSuperUser: "isSuperUser",
    }),

    dealName() {
      return this.getDealName({ deal: this.activeDeal })
    },
  },
}
</script>
